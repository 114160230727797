import React, { useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useSiteContext } from '../../../../context/site/SiteProvider'
import { ListGroup } from 'react-bootstrap'
import { IAuditLogData } from '../../../../context/site/types'
import RwCloseButton from '../../../molecules/RwButton/RwCloseButton'
import StaffAvatarIcon from '../Icon/StaffAvatarIcon'

interface IAuditLogModal {
  showModal: boolean
  table: string
  keyValue: string
  title?: string
  handleClose: () => void
  fieldMap?: any
  hideFields?: Array<string>
  isPayments?: boolean
}

const AuditLogModal: React.FC<IAuditLogModal> = ({
  showModal,
  handleClose,
  title = 'Audit Logs',
  table,
  keyValue,
  fieldMap = {},
  hideFields = [],
  isPayments,
}) => {
  const { auditList, getAuditList, clearAuditList } = useSiteContext()

  useEffect(() => {
    if (table && showModal) {
      getAuditList(table, keyValue, isPayments)
    }

    return () => {
      clearAuditList()
    }
  }, [table, keyValue, showModal])

  const auditLogList = auditList.filter((audit_log: IAuditLogData) => {
    return !hideFields.includes(audit_log.field_name)
  })

  return (
    <Modal
      className="rw-audit-log-modal p-0 m-0"
      show={showModal}
      onHide={handleClose}
      animation={false}
    >
      {title ? (
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      ) : (
        <></>
      )}

      <Modal.Body className="m-0 p-0 rw-audit-log-modal-body">
        <ListGroup>
          {auditLogList.map((audit_log: IAuditLogData) => {
            const label = fieldMap.hasOwnProperty(audit_log.field_name)
              ? fieldMap[audit_log.field_name]
              : audit_log.field_name
            return (
              <ListGroup.Item key={audit_log.id}>
                <div className="d-flex">
                  <div className="me-2">
                    <StaffAvatarIcon
                      photo_url={audit_log.staff_photo_url}
                      staff_name={audit_log.staff_name}
                    />
                  </div>
                  <div>
                    <div>
                      {audit_log.staff_name} {audit_log.action}{' '}
                      <span className="fw-bold text-capitalize">{label}</span>{' '}
                      <span className="fst-italic ms-2">
                        {audit_log.action_date}
                      </span>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: audit_log.description,
                      }}
                    ></div>{' '}
                  </div>
                </div>
              </ListGroup.Item>
            )
          })}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <RwCloseButton className="me-auto" onClick={handleClose} />
      </Modal.Footer>
    </Modal>
  )
}
export default AuditLogModal
