export const ORDERS_GET_PERM = 'spark.orders.get'
export const ORDERS_UPDATE_PERM = 'spark.orders.update'
export const ORDERS_PRICING_UPDATE_PERM = 'spark.orders.pricing.update'
export const ORDERS_SHIPMENTS_GET_PERM = 'spark.orders.shipments.get'
export const ORDERS_SHIPMENTS_UPDATE_PERM = 'spark.orders.shipments.update'
export const ORDERS_PAYMENTS_GET_PERM = 'spark.orders.payments.get'
export const ORDERS_PAYMENTS_UPDATE_PERM = 'spark.orders.payments.update'
export const ORDERS_CREDIT_MEMOS_GET_PERM = 'spark.orders.credit-memos.get'
export const ORDERS_CREDIT_MEMOS_UPDATE = 'spark.orders.credit-memos.update'
export const ORDERS_TAGS_GET_PERM = 'spark.orders.tags.get'
export const ORDERS_TAGS_UPDATE_PERM = 'spark.orders.tags.update'
export const ACCOUNTS_GET_PERM = 'spark.accounts.get'
export const ACCOUNTS_UPDATE_PERM = 'spark.accounts.update'
export const ACCOUNTS_CLUBS_GET_PERM = 'spark.accounts.clubs.get'
export const ACCOUNTS_CLUBS_UPDATE_PERM = 'spark.accounts.clubs.update'
export const PRODUCTS_GET_PERM = 'spark.products.get'
export const PRODUCTS_UPDATE_PERM = 'spark.products.update'
export const PRODUCTS_STATUS_UPDATE_PERM = 'spark.products.status.update'
export const PRODUCTS_COUPON_CODES_GET_PERM = 'spark.products.coupon-codes.get'
export const PRODUCTS_COUPON_CODES_UPDATE = 'spark.products.coupon-codes.update'
export const PRODUCTS_PRICING_GET_PERM = 'spark.products.pricing.get'
export const PRODUCTS_PRICING_UPDATE_PERM = 'spark.products.pricing.update'
export const PRODUCTS_RETAIL_GET_PERM = 'spark.products.retail.get'
export const PRODUCTS_RETAIL_UPDATE_PERM = 'spark.products.retail.update'
export const PRODUCTS_RETAIL_PIECE_PRODUCTS_GET =
  'spark.products.retail.piece-products.get'
export const PRODUCTS_RETAIL_PIECE_PRODUCTS_UPDATE =
  'spark.products.retail.piece-products.update'
export const PRODUCTS_CATEGORIES_GET_PERM = 'spark.products.categories.get'
export const PRODUCTS_CATEGORIES_UPDATE = 'spark.products.categories.update'
export const PRODUCTS_BRANDS_GET_PERM = 'spark.products.brands.get'
export const PRODUCTS_BRANDS_UPDATE_PERM = 'spark.products.brands.update'
export const PRODUCTS_SUPPLIERS_GET_PERM = 'spark.products.suppliers.get'
export const PRODUCTS_SUPPLIERS_UPDATE_PERM = 'spark.products.suppliers.update'
export const PRODUCTS_PROMOS_GET_PERM = 'spark.products.promos.get'
export const PRODUCTS_PROMOS_UPDATE_PERM = 'spark.products.promos.update'
export const PRODUCTS_CUSTOM_ASSORTMENTS_GET =
  'spark.products.custom-assortments.get'
export const PRODUCTS_CUSTOM_ASSORTMENTS_UPDATE =
  'spark.products.custom-assortments.update'
export const INVENTORY_GET_PERM = 'spark.inventory.get'
export const INVENTORY_UPDATE_PERM = 'spark.inventory.update'
export const INVENTORY_AUDIT_GET_PERM = 'spark.inventory.audit.get'
export const ACCOUNTING_GET_PERM = 'spark.accounting.get'
export const ACCOUNTING_UPDATE_PERM = 'spark.accounting.update'
export const TASKS_GET_PERM = 'spark.tasks.get'
export const TASKS_UPDATE_PERM = 'spark.tasks.update'
export const FULFILLMENT_GET_PERM = 'spark.fulfillment.get'
export const FULFILLMENT_UPDATE_PERM = 'spark.fulfillment.update'
export const WAREHOUSE_WAREHOUSE_CAPACITY_GET =
  'spark.warehouse.warehouse-capacity.get'
export const WAREHOUSE_WAREHOUSE_CAPACITY_UPDATE =
  'spark.warehouse.warehouse-capacity.update'
export const WAREHOUSE_ORDER_PICKUP_GET = 'spark.warehouse.order-pickup.get'
export const WAREHOUSE_ORDER_PICKUP_UPDATE =
  'spark.warehouse.order-pickup.update'
export const SETTINGS_STAFF_GET_PERM = 'spark.settings.staff.get'
export const SETTINGS_STAFF_UPDATE_PERM = 'spark.settings.staff.update'
export const SETTINGS_STAFF_FORCE_LOGOUT_ALL_PERM =
  'spark.settings.staff.force-logout-all-users'
export const SETTINGS_STAFF_FORCE_LOGOUT_PERM =
  'spark.settings.staff.force-logout'
export const SETTINGS_SEASONS_GET_PERM = 'spark.settings.seasons.get'
export const SETTINGS_SEASONS_UPDATE_PERM = 'spark.settings.seasons.update'
export const SETTINGS_SCHEDULED_TASK_GET = 'spark.settings.scheduled-task.get'
export const SETTINGS_SCHEDULED_TASK_UPDATE =
  'spark.settings.scheduled-task.update'
export const SETTINGS_REDIRECTS_GET_PERM = 'spark.settings.redirects.get'
export const SETTINGS_REDIRECTS_UPDATE_PERM = 'spark.settings.redirects.update'
export const SETTINGS_STORE_SETTINGS_GET = 'spark.settings.store-settings.get'
export const SETTINGS_STORE_SETTINGS_UPDATE =
  'spark.settings.store-settings.update'
export const SETTINGS_ACCOUNTING_INTEGRATION_GET =
  'spark.settings.accounting-integration.get'
export const SETTINGS_ACCOUNTING_INTEGRATION_UPDATE =
  'spark.settings.accounting-integration.update'
export const SETTINGS_DEPLOYMENT_ENVIRONMENTS_GET =
  'spark.settings.deployment-environments.get'
export const SETTINGS_DEPLOYMENT_ENVIRONMENTS_UPDATE =
  'spark.settings.deployment-environments.update'
export const SETTINGS_PICKUP_DAYS_GET_PERM = 'spark.settings.pickup-days.get'
export const SETTINGS_PICKUP_DAYS_UPDATE = 'spark.settings.pickup-days.update'
export const SETTINGS_APP_SETTINGS_GET_PERM = 'spark.settings.app-settings.get'
export const SETTINGS_APP_SETTINGS_UPDATE = 'spark.settings.app-settings.update'
export const SETTINGS_BANNERS_TILES_GET = 'spark.settings.banners-tiles.get'
export const SETTINGS_BANNERS_TILES_UPDATE =
  'spark.settings.banners-tiles.update'
export const AIRTABLE_CONFIG_GET_PERM = 'spark.airtable-config.get'
export const AIRTABLE_CONFIG_UPDATE_PERM = 'spark.airtable-config.update'
export const WMS_INVENTORY_TAKER_GET_PERM = 'spark.wms.inventory-taker.get'
export const WMS_INVENTORY_TAKER_UPDATE = 'spark.wms.inventory-taker.update'
export const WMS_INVENTORY_TAKER_APPLY_UPDATES =
  'spark.wms.inventory-taker.apply-updates'
export const WMS_CONTAINERS_GET_PERM = 'spark.wms.containers.get'
export const WMS_CONTAINERS_UPDATE_PERM = 'spark.wms.containers.update'
export const WMS_RESTOCKS_GET_PERM = 'spark.wms.restocks.get'
export const WMS_RESTOCKS_UPDATE_PERM = 'spark.wms.restocks.update'
export const WMS_ORDERS_GET_PERM = 'spark.wms.orders.get'
export const WMS_ORDERS_UPDATE_PERM = 'spark.wms.orders.update'
export const WMS_PROCESSES_GET_PERM = 'spark.wms.processes.get'
export const WMS_PROCESSES_UPDATE_PERM = 'spark.wms.processes.update'
export const WMS_RESTOCK_OVERFLOW_GET_PERM = 'spark.wms.restock-overflow.get'
export const WMS_RESTOCK_OVERFLOW_UPDATE = 'spark.wms.restock-overflow.update'
export const WMS_AUDIT_GET_PERM = 'spark.wms.audit.get'
export const WMS_GET_PERM = 'spark.wms.get'
export const DROP_SHIP_ORDERS_GET_PERM = 'spark.drop-ship.orders.get'
export const DROP_SHIP_ORDERS_UPDATE_PERM = 'spark.drop-ship.orders.update'
export const DROP_SHIP_SHIPMENTS_GET_PERM = 'spark.drop-ship.shipments.get'
export const DROP_SHIP_SHIPMENTS_UPDATE = 'spark.drop-ship.shipments.update'
export const DROP_SHIP_CUSTOMERS_GET_PERM = 'spark.drop-ship.customers.get'
export const DROP_SHIP_CUSTOMERS_UPDATE = 'spark.drop-ship.customers.update'
export const DROP_SHIP_FULFILLMENT_DOCS_GET =
  'spark.drop-ship.fulfillment-docs.get'
export const DROP_SHIP_FULFILLMENT_DOCS_UPDATE =
  'spark.drop-ship.fulfillment-docs.update'
export const DROP_SHIP_CUSTOMER_INVOICES_GET =
  'spark.drop-ship.customer-invoices.get'
export const DROP_SHIP_CUSTOMER_INVOICES_UPDATE =
  'spark.drop-ship.customer-invoices.update'
export const DROP_SHIP_VENDOR_INVOICES_GET =
  'spark.drop-ship.vendor-invoices.get'
export const DROP_SHIP_VENDOR_INVOICES_UPDATE =
  'spark.drop-ship.vendor-invoices.update'
export const DROP_SHIP_PRICE_LISTS_GET_PERM = 'spark.drop-ship.price-lists.get'
export const DROP_SHIP_PRICE_LISTS_UPDATE = 'spark.drop-ship.price-lists.update'
export const DROP_SHIP_STOCK_LISTS_GET_PERM = 'spark.drop-ship.stock-lists.get'
export const DROP_SHIP_STOCK_LISTS_UPDATE = 'spark.drop-ship.stock-lists.update'
export const DROP_SHIP_GET_PERM = 'spark.drop-ship.get'
export const DROP_SHIP_UPDATE_PERM = 'spark.drop-ship.update'
export const FULFILLMENT_SHIPPING_CALENDAR_GET =
  'spark.fulfillment.shipping-calendar.get'
export const IAM_GET_PERM = 'spark.iam.get'
export const IAM_UPDATE_PERM = 'spark.iam.update'
export const PRODUCT_EDITOR_GET_PERM = 'spark.products.editor.get'
export const PRODUCT_EDITOR_SALES_GET_PERM = 'spark.products.editor.sales.get'
export const PRODUCT_EDITOR_SALES_UPDATE_PERM =
  'spark.products.editor.sales.update'
export const PRODUCT_EDITOR_WAREHOUSE_GET_PERM =
  'spark.products.editor.warehouse.get'
export const PRODUCT_EDITOR_WAREHOUSE_UPDATE_PERM =
  'spark.products.editor.warehouse.update'
export const PRODUCT_EDITOR_MARKETING_GET_PERM =
  'spark.products.editor.marketing.get'
export const PRODUCT_EDITOR_MARKETING_UPDATE_PERM =
  'spark.products.editor.marketing.update'
export const PRODUCT_EDITOR_PRO_LINE_GET_PERM =
  'spark.products.editor.pro-line.get'
export const PRODUCT_EDITOR_PRO_LINE_UPDATE_PERM =
  'spark.products.editor.pro-line.update'
export const PRODUCT_EDITOR_RETAIL_GET_PERM = 'spark.products.editor.retail.get'
export const PRODUCT_EDITOR_RETAIL_UPDATE_PERM =
  'spark.products.editor.retail.update'
export const PRODUCT_EDITOR_PURCHASING_GET_PERM =
  'spark.products.editor.purchasing.get'
export const PRODUCT_EDITOR_PURCHASING_UPDATE_PERM =
  'spark.products.editor.purchasing.update'
export const PRODUCT_LISTS_GET_PERM = 'spark.settings.product-lists.get'
export const SHIPPING_GET_PERM = 'spark.fulfillment.shipping.get'
export const SHIPPING_UPDATE_PERM = 'spark.fulfillment.shipping.update'
export const SITEMAP_GET_PERM = 'spark.products.sitemap.get'
export const CATEGORIES_GET_PERM = 'spark.products.categories.get'
export const BRANDS_GET_PERM = 'spark.products.brands.get'

export const MARKETING_PAGES_GET_PERM = 'spark.settings.marketing-pages.get'
export const MARKETING_PAGES_PUBLISH_PERM =
  'spark.settings.marketing-pages.publish'
export const ORDER_PRODUCT_PRICE_UPDATE_PERM =
  'spark.orders.order-product-price.update'
export const ORDER_LOCK_UPDATE_PERM = 'spark.orders.lock.update'

export const SITE_MENUS_GET_PERM = 'spark.settings.site-menus.get'
export const SITE_MENUS_UPDATE_PERM = 'spark.settings.site-menus.update'
export const SITE_MENUS_DELETE_PERM = 'spark.settings.site-menus.delete'
export const ORDER_BALANCE_STATUS_UPDATE_PERM =
  'spark.orders.balance.status.update'
