import React, { ReactNode, useState } from 'react'
import { Container, Row, Col, Stack, Badge } from 'react-bootstrap'
import RwFormikCheckbox from '../../molecules/RwFormikCheckbox'
import { IProductIgnitionType } from '../../../context/products/types'
import { useUserContext } from '../../../context/user/UserProvider'

export interface IProductIgnitionTypes {
  ignition_types: Array<IProductIgnitionType>
  prefix: string
  label?: string | ReactNode
  [x: string]: any
}

const ProductIgnitionTypes: React.FC<IProductIgnitionTypes> = ({
  ignition_types,
  prefix,
  label = null,
  edit = false,
  values,
}) => {
  const { isDarkMode } = useUserContext()

  if (!ignition_types) {
    return <></>
  }

  return (
    <>
      {label && <div className="mb-1">{label}</div>}
      <div
        className={`border rounded p-3 mb-3 ${
          isDarkMode ? 'bg-dark' : 'bg-white'
        }`}
      >
        <Container fluid>
          <Row>
            {ignition_types.map((ignition_type: IProductIgnitionType) => {
              return (
                <Col sm={12} md={6} key={ignition_type.id}>
                  <RwFormikCheckbox
                    name={`${prefix}${ignition_type.id}`}
                    label={ignition_type.ignition_type_name}
                  />
                </Col>
              )
            })}
          </Row>
        </Container>
      </div>
    </>
  )
}

export default ProductIgnitionTypes
