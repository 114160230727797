import React from 'react'
import { CDN_URI } from '../../../../constants/cdn'
import Image from 'react-bootstrap/Image'

export interface CdnSrcProps {
  path: string | undefined
  fluid?: boolean
  [x: string]: any
}

const CdnImg: React.FC<CdnSrcProps> = (props: CdnSrcProps) => {
  const { path, fluid = false, ...rest } = props
  const src = `${CDN_URI}${path}`
  return <Image fluid={fluid} className="bg-transparent" src={src} {...rest} />
}

export default CdnImg
