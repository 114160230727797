import React, { JSXElementConstructor, ReactElement } from 'react'
import { useUserContext } from '../../context/user/UserProvider'

export interface IRwDisplayField {
  value: string | undefined
  label?: string
  className?: string
  suffix?: ReactElement<any, string | JSXElementConstructor<any>>
  style?: Object
  scroll?: boolean
  [x: string]: any
}

const RwDisplayField: React.FC<IRwDisplayField> = ({
  value,
  className = '',
  label = null,
  suffix = null,
  style = {},
  scroll = false,
  ...rest
}) => {
  const { isDarkMode } = useUserContext()

  const newSuffix = suffix
    ? React.cloneElement(suffix, {
        style: { height: '37px' },
        tabIndex: 99,
      })
    : null

  const roundedClass = newSuffix ? 'rounded-start' : 'rounded'

  return (
    <div className={`mb-3 ${className}`}>
      {label ?? <div>{label}</div>}
      <div className="d-flex btn-group">
        <div
          className={`${
            scroll ? 'overflow-scroll' : ''
          } border p-2 w-100 ${roundedClass} ${
            isDarkMode ? 'rw-display-field-box-dm' : 'rw-display-field-box'
          }`}
          style={style}
        >
          {value}
        </div>
        {newSuffix}
      </div>
    </div>
  )
}

export default RwDisplayField
