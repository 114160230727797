export const GET_ORDER = 'GET_ORDER'

export const orderFieldMap = {
  orders_products_id: 'Product',
  products_quantity: 'Product Quantity',
  orders_locked: 'Order Unlocked',
  sold_price: 'Price',
  final_price: 'Cost',
  sold_price_before_override: 'Sold Price Before Override',
  number_used: 'Coupon Number Used',
  autocalc: 'Percentage',
  products_name: 'Product Name',
  tag_id: 'Order Tag',
  is_ready_to_schedule: 'Ready to Schedule',
  is_pickup_for_shipping: 'Pickup Order',
  orders_status: 'Order Status',
  fulfillment_status: 'Fulfillment Status',
  orders_total_id: 'Order Total',
  orders_id: 'Products', // For orders id updates on product move, orders_products.orders_id
  delivery_country: 'Shipping Country',
  delivery_city: 'Shipping City',
  shipping_date: 'Shipping Date',
  customers_telephone: 'Shipping Phone',
  delivery_name: 'Shipping Name',
  delivery_company: 'Shipping Company',
  delivery_street_address: 'Shipping Street Address',
  delivery_postcode: 'Shipping ZIP Code',
  delivery_state: 'Shipping State',
  delivery_suburb: 'Shipping Apt/Suite',
  orders_status: 'Order Status',
  rush: 'Rush Shipping',
  DestinationHasLoadingDock: 'Has Loading Dock',
  DestinationRequiresLiftgate: 'Needs Liftgate',
  DestinationIsResidential: 'Residential Destination',
  DestinationArrivalNotification: 'Arrival Notification',
  DestinationHasPallet_Jack: 'Has Pallet Jack',
  DestinationHasForklift: 'Has Forklift',
  pickup_date: 'Pickup Date',
  pickup_time: 'Pickup Time',
}

export const COUPON_CODE_LINE_ITEM_ID = 2
export const PROCESSING_FEE_LINE_ITEM_ID = 4

export const nonEditableOrderTotals = [
  'Total',
  'Subtotal',
  'Subtotal Non Discount',
]
