import { createContext, useReducer, useContext } from 'react'
import orderReducer from './OrderReducer'
import { IOrderDetails } from './types'
import { IContextProviderProps } from '../../types/interfaces'
import { api } from '../../helpers/Api'
import { API_ORDERS_URL } from '../apiconfig'
import { IOrderState } from './types'
import { GET_ORDER } from './constants'
import toast from '../../components/molecules/RwToast'

export const initialState: IOrderState = {
  orderDetails: null,
  getOrderDetails: () => {},
  updateOrderDetails: () => {},
  cleanupOrderDetails: () => {},
  cleanupOrderPage: () => {},
}

export const OrderContext = createContext<IOrderState>(initialState)
export const useOrderContext = () => useContext(OrderContext)

export const OrderProvider = (props: IContextProviderProps): JSX.Element => {
  const { children } = props
  const [state, dispatch] = useReducer(orderReducer, initialState)

  const cleanupOrderPage = () => {
    cleanupOrderDetails()
  }

  const cleanupOrderDetails = () => {
    dispatch({ type: GET_ORDER, payload: null })
  }

  const getOrderDetails = async (orders_id: string) => {
    const response = await api.get(
      `${API_ORDERS_URL}order-details/${orders_id}`,
    )

    if (response.data.success) {
      dispatch({ type: GET_ORDER, payload: response.data.data })
    } else {
      toast.error(response.data.message || 'Error fetching order details')
    }
  }

  const updateOrderDetails = async (orderDetails: IOrderDetails) => {
    const response = await api.post(`${API_ORDERS_URL}order-details/update`, {
      ...orderDetails,
      override: orderDetails.override,
    })

    if (response.data.success) {
      toast.success('Saved')
      dispatch({ type: GET_ORDER, payload: response.data.data })
    } else {
      toast.error(response.data.message || 'Error saving')
    }
  }

  const value = {
    ...state,
    getOrderDetails,
    updateOrderDetails,
    cleanupOrderDetails,
    cleanupOrderPage,
  }

  return <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
}
