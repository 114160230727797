import React, {
  forwardRef,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
} from 'react'
import { FloatingLabel, Form, InputGroup } from 'react-bootstrap'

export interface RwInputGroupProps {
  label?: string | ReactNode
  hint?: string | ReactNode
  type?: string
  name?: string
  id?: string
  prefix?: ReactElement<any, string | JSXElementConstructor<any>>
  suffix?: ReactElement<any, string | JSXElementConstructor<any>>
  flush?: boolean
  [x: string]: any
}

const RwInputGroup = forwardRef<HTMLInputElement, RwInputGroupProps>(
  (props: RwInputGroupProps, ref) => {
    const {
      type = 'text',
      label = '',
      hint = '',
      name = props.name || props.id,
      prefix = null,
      suffix = null,
      flush = false,
      ...rest
    } = props

    const newPrefix = prefix
      ? React.cloneElement(prefix, {
          style: { height: '54px' },
          tabIndex: 99,
        })
      : null

    const newSuffix = suffix
      ? React.cloneElement(suffix, {
          style: { height: '54px' },
          className: 'rounded-end',
          tabIndex: 99,
        })
      : null

    const marginClass = flush ? '' : 'mb-2'

    return (
      <div className={marginClass}>
        {label ? (
          <InputGroup className="flex-nowrap">
            {newPrefix}
            <FloatingLabel label={label}>
              <Form.Control type={type} name={name} ref={ref} {...rest} />
              {typeof hint === 'string' && hint.length > 0 && (
                <Form.Text className="text-muted ms-2">{hint}</Form.Text>
              )}
              {React.isValidElement(hint) && hint}
            </FloatingLabel>
            {newSuffix}
          </InputGroup>
        ) : (
          <Form.Group controlId={`${name}_form_group`}>
            <InputGroup className="flex-nowrap">
              {newPrefix}
              <Form.Control type={type} name={name} ref={ref} {...rest} />
              {newSuffix}
            </InputGroup>
            {typeof hint === 'string' && hint.length > 0 && (
              <Form.Text className="text-muted ms-2">{hint}</Form.Text>
            )}
            {React.isValidElement(hint) && hint}
          </Form.Group>
        )}
      </div>
    )
  },
)

export default RwInputGroup
